<template>
  <div class="input-image" :style="`--image: url('${source}');--width:${width};--height:${height}`">
    <label class="input-image-area" :class="`${file ? '__fill' : '__void'}`">
      <div class="input-image-image">
        <input type="file" hide hidden :name="name" @change="readURL($event)" :accept="accepted" />
      </div>
      <div class="input-image-options">
        <GradientButton :label="$locale['words']['select_file']" icon="mf_camera" dir="rtl" :buttonStyle="buttonStyle" class="__button" />
      </div>
    </label>
  </div>
</template>

<script>
export default {
  props: ["accept", "value", "name", "width", "height", "buttonStyle"],
  data: function() {
    return {
      accepted: this.accept || "image/png, image/jpeg",
      source: this.value || "",
      file: null,
    };
  },
  methods: {
    readURL: function(evt) {
      let [self, input, reader] = [this, evt.target, new FileReader()];

      reader.onload = function(e) {
        self.source = e.target.result;
      };

      if (input.files[0]) {
        reader.readAsDataURL(input.files[0]);
        this.file = input.files[0];
      }
    },
  },
};
</script>

<style lang="scss">
.input-image {
  position: relative;
  &-image {
    display: inline-block;
    width: var(--width);
    height: var(--height);
    min-height: 200px;
    min-width: 200px;
    cursor: pointer;
    background-image: var(--image);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
  }
  &-area {
    display: block;
  }
  &-options {
    margin: var(--mpadding) 0 0 0;
    position: relative;
    text-align: center;
  }
}
</style>
